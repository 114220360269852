import { Component, createRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { deleteCategory, editCategory, getIpCategories } from "../api";
import { Info, Loading } from "../other";

class EditCategory extends Component {

    constructor(props) {

        super(props);

        this.categoryInput = createRef();
        this.nameInput = createRef();

        this.state = { requesting: false, info: null, categories: [] };
    }

    componentDidMount() {

        this.setState({ requesting: true });
        getIpCategories().then((categories) => {
            this.setState({ requesting: false, categories }, () => {
                this.setCategory(parseInt(this.props.params.categoryId));
            });
        }).catch(() => {
            this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
        });
    }

    setCategory(categoryId) {
        const category = this.state.categories.find((category) => category.id === categoryId);
        if (!category) {
            this.setState({ info: <Info>Cette catégorie n'existe pas !</Info> });
            this.setCategory(this.state.categories[0].id);
            return;
        }
        this.categoryInput.current.value = category.id;
        this.nameInput.current.value = category.name;
    }

    render() {

        document.title = "Modifier une catégorie - Collège Jeanne d'Arc";

        const processEditCategory = () => {

            this.setState({ requesting: true, info: null });
            editCategory(this.categoryInput.current.value, this.nameInput.current.value).then(() => {
                this.props.navigate("/ip");
            }).catch((message) => {
                if (message === "Invalid token") {
                    localStorage.removeItem("token");
                    document.location.reload();
                } else if (message === "Missing name")
                    this.setState({ requesting: false, info: <Info>Aucun nom !</Info> });
                else
                    this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
            });
        }

        const processDeleteCategory = () => {

            const category = this.state.categories.find((category) => category.id === parseInt(this.categoryInput.current.value));
            if (!category) return;

            if (!window.confirm("Voulez vous vraiment supprimer la catégorie " + category.name + " ?"))
                return;

            this.setState({ requesting: true, info: null });
            deleteCategory(this.categoryInput.current.value).then(() => {
                this.props.navigate("/ip");
            }).catch((message) => {
                if (message === "Invalid token") {
                    localStorage.removeItem("token");
                    document.location.reload();
                } else
                    this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
            });
        }

        return <div className="ip">
            <div className="title">{document.title}</div>

            {this.state.requesting ? <Loading /> : null}
            {this.state.info}

            <div>
                <span className="hint">Catégorie à modifier :</span>
                <select ref={this.categoryInput} disabled={this.state.requesting} onChange={() => this.setCategory(parseInt(this.categoryInput.current.value))}>
                    {this.state.categories.map((category) => <option key={category.id} value={category.id}>{category.name}</option>)}
                </select>
            </div>

            <div>
                <span className="hint">Nom :</span>
                <input ref={this.nameInput} disabled={this.state.requesting} autoFocus onKeyPress={(event) => { if (event.key === "Enter") processEditCategory(); }} />
            </div>

            <div>
                <button disabled={this.state.requesting} onClick={processEditCategory}>Modifier</button>
                <button disabled={this.state.requesting} onClick={processDeleteCategory}>Supprimer</button>
            </div>
        </div>;
    }
}

// eslint-disable-next-line
export default (props) => <EditCategory {...props} navigate={useNavigate()} params={useParams()} />;