import { Component } from "react";
import { useNavigate } from "react-router-dom";
import { getIp, getIpCategories } from "../api";
import { Info, Loading } from "../other";

import "../styles/ip.scss";

class Print extends Component {

    constructor(props) {

        super(props);

        this.state = { requesting: false, info: null, categories: [], ip: [] };
    }

    componentDidMount() {

        this.setState({ requesting: true });
        getIp().then((ip) => {
            getIpCategories().then((categories) => {
                this.setState({ requesting: false, ip, categories }, () => {
                    window.print();
                    this.props.navigate("/ip");
                });
            }).catch(() => {
                this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
            });
        }).catch(() => {
            this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
        });
    }

    render() {

        document.title = "IP - Collège Jeanne d'Arc";

        let ip = this.state.ip.map((ip) => ({
            name: ip.name,
            category: this.state.categories.find((category) => category.id === ip.categoryId).name,
            description: ip.description
        }));

        for (let i = 1; i <= 254; i++)
            if (!this.state.ip.some((ip) => ip.name === `192.168.0.${i}`))
                ip.push({ name: `192.168.0.${i}` });

        ip = ip.sort((a, b) => parseInt(a.name.split(".")[3]) - parseInt(b.name.split(".")[3]));

        return <div className="ipprint">
            {this.state.requesting ? <Loading /> : null}
            {this.state.info}

            {this.state.requesting ? null : <table>
                <thead><tr>
                    <th>IP</th>
                    <th>Catégorie</th>
                    <th>Description</th>
                </tr></thead>
                <tbody>{ip.map((ip) => <tr>
                    <td style={{ color: ip.category ? "red" : "green" }}>{ip.name}</td>
                    <td>{ip.category}</td>
                    <td>{ip.description}</td>
                </tr>)}</tbody>
            </table>}
        </div>;
    }
}

// eslint-disable-next-line
export default (props) => <Print {...props} navigate={useNavigate()} />;