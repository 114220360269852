import { Component, createRef } from "react";
import { useNavigate } from "react-router-dom";
import { newCategory } from "../api";
import { Info, Loading } from "../other";

class NewCategory extends Component {

    constructor(props) {

        super(props);

        this.nameInput = createRef();

        this.state = { requesting: false, info: null };
    }

    render() {

        document.title = "Ajouter une catégorie - Collège Jeanne d'Arc";

        const processNewCategory = () => {

            this.setState({ requesting: true, info: null });
            newCategory(this.nameInput.current.value).then(() => {
                this.props.navigate("/ip");
            }).catch((message) => {
                if (message === "Invalid token") {
                    localStorage.removeItem("token");
                    document.location.reload();
                } else if (message === "Missing name")
                    this.setState({ requesting: false, info: <Info>Aucun nom !</Info> });
                else
                    this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
            });
        }

        return <div className="ip">
            <div className="title">{document.title}</div>

            {this.state.requesting ? <Loading /> : null}
            {this.state.info}

            <div>
                <span className="hint">Nom :</span>
                <input ref={this.nameInput} disabled={this.state.requesting} autoFocus onKeyPress={(event) => { if (event.key === "Enter") processNewCategory(); }} />
            </div>

            <div><button disabled={this.state.requesting} onClick={processNewCategory}>Ajouter</button></div>
        </div>;
    }
}

// eslint-disable-next-line
export default (props) => <NewCategory {...props} navigate={useNavigate()} />;