import { Component } from "react";

export default class Home extends Component {

    render() {

        document.title = "Accueil - Collège Jeanne d'Arc";

        return <div>
            <div className="title">{document.title}</div>

            <div>Il n'y a rien sur la page d'accueil pour le moment, choisissez votre catégorie dans la barre de navigation en haut</div>
        </div>;
    }
}
