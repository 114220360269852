import { Component } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Footer, Header, NotFound } from "./other";
import Home from "./home";
import Login from "./login";

import IpHome from "./ip/home";
import IpPrint from "./ip/print";
import NewIp from "./ip/newIp";
import EditIp from "./ip/editIp";
import NewIpCategory from "./ip/newCategory";
import EditIpCategory from "./ip/editCategory";

import SelfOrderUpdate from "./selfOrder/update";
import SelfOrderControl from "./selfOrder/control";
import SelfOrderDisplay from "./selfOrder/display";

import "./styles/common.scss";

class Website extends Component {

    componentDidMount() {
        if (document.readyState === "complete") this.updateContentHeight();
        else window.addEventListener("load", () => this.updateContentHeight());
        window.addEventListener("resize", () => this.updateContentHeight());
    }

    updateContentHeight() {
        if (["/ip/print", "/selforder/display"].includes(document.location.pathname.toLowerCase()) || !localStorage.getItem("token")) return;
        const content = document.getElementById("content");
        const header = document.getElementById("header");
        const footer = document.getElementById("footer");
        content.style.minHeight = (window.innerHeight - header.offsetHeight - footer.offsetHeight) + "px";
    }

    render() {

        if (!localStorage.getItem("token"))
            return <div className="content"><Login /></div>;

        return <BrowserRouter>
            <Header />
            <div className="content" id="content">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/ip" element={<IpHome />} />
                    <Route path="/ip/print" element={<IpPrint />} />
                    <Route path="/ip/new" element={<NewIp />} />
                    <Route path="/ip/:ip" element={<EditIp />} />
                    <Route path="/ip/categories/new" element={<NewIpCategory />} />
                    <Route path="/ip/categories/:categoryId" element={<EditIpCategory />} />
                    <Route path="/selfOrder/:day" element={<SelfOrderUpdate />} />
                    <Route path="/selfOrder/control" element={<SelfOrderControl />} />
                    <Route path="/selfOrder/display" element={<SelfOrderDisplay />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
            <Footer />
        </BrowserRouter>;
    }
}

createRoot(document.getElementById("root")).render(<Website />);
