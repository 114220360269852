import { Component, createRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { addSelfOrderItem, deleteSelfOrderItem, editSelfOrderItem, GATEWAY_HOST } from "../api";
import { Info, Loading } from "../other";

import "../styles/selfOrder.scss";

class Update extends Component {

    constructor(props) {

        super(props);

        this.newInputRef = createRef();
        this.editInputRef = createRef();

        this.ws = null;
        this.state = { requesting: true, info: null, selectedDay: 0, selfOrder: [], adding: null, editing: null };
    }

    componentDidMount() {
        this.connect();
    }

    componentDidUpdate(oldProps) {
        if (this.props.params.day !== oldProps.params.day) this.checkSelectedDay();
    }

    connect() {

        this.setState({ requesting: true });

        this.ws = new WebSocket(GATEWAY_HOST);

        this.ws.addEventListener("open", () => {
            this.ws.send(JSON.stringify({
                command: "LOGIN",
                token: localStorage.getItem("token")
            }));
        });

        this.ws.addEventListener("close", (event) => {
            if (event.reason === "Invalid token") {
                localStorage.removeItem("token");
                document.location.reload();
            } else
                setTimeout(() => this.connect(), 1000);
        });

        this.ws.addEventListener("message", (event) => {

            let message;
            try {
                message = JSON.parse(event.data);
            } catch (error) {
                return;
            }

            if (message.event === "LOGGED")
                this.setState({ requesting: false, selfOrder: message.selfOrderDays }, () => this.checkSelectedDay());
            else if (message.event === "SELF_ORDER_ADD") {
                const list = this.state.selfOrder.find((day) => day.day === message.day)[message.type.toLowerCase()];
                list.push({ name: message.name, position: message.position });
                this.setState({ selfOrder: this.state.selfOrder });
            } else if (message.event === "SELF_ORDER_EDIT") {
                const oldList = this.state.selfOrder.find((day) => day.day === message.day)[message.type.toLowerCase()];
                const newList = this.state.selfOrder.find((day) => day.day === message.day)[message.newType.toLowerCase()];
                const item = oldList.find((item) => item.position === message.position);
                oldList.splice(oldList.indexOf(item), 1);
                oldList.filter((val) => val.position > item.position).forEach((item) => item.position--);
                newList.filter((val) => val.position >= message.newPosition).forEach((item) => item.position++);
                newList.push({ name: message.newName, position: message.newPosition });
                this.setState({ selfOrder: this.state.selfOrder });
            } else if (message.event === "SELF_ORDER_DELETE") {
                const list = this.state.selfOrder.find((day) => day.day === message.day)[message.type.toLowerCase()];
                const item = list.find((item) => item.position === message.position);
                list.splice(list.indexOf(item), 1);
                list.filter((val) => val.position > item.position).forEach((item) => item.position--);
                this.setState({ selfOrder: this.state.selfOrder });
            } else if (message.event === "HEARTBEAT")
                this.ws.send(JSON.stringify({ command: "HEARTBEAT", }));
        });
    }

    checkSelectedDay() {
        const selectedDay = this.state.selfOrder.find((day) => day.day === parseInt(this.props.params.day));
        if (!selectedDay)
            this.setState({ selectedDay: 1, info: <Info>Ce jour n'existe pas !</Info>, adding: null });
        else
            this.setState({ selectedDay: selectedDay.day, adding: null });
    }

    render() {

        document.title = "Ordre cantine - Collège Jeanne d'Arc";

        if (this.state.selectedDay === 0) {
            return <div className="selfOrder">
                <div className="title">{document.title}</div>

                {this.state.requesting ? <Loading /> : null}
                {this.state.info}
            </div>;
        }

        const onDragEnd = (result) => {

            if (!result.destination || (result.destination.droppableId === result.source.droppableId && result.destination.index === result.source.index))
                return;

            const oldItemName = this.state.selfOrder.find((day) => day.day === this.state.selectedDay)[result.source.droppableId]
                .find((item) => item.position === result.source.index).name;

            this.setState({ requesting: true, info: null });
            editSelfOrderItem(this.state.selectedDay, result.source.droppableId, result.source.index, oldItemName, result.destination.index, result.destination.droppableId)
                .then(() => this.setState({ requesting: false }))
                .catch(() => this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> }));
        }

        const addItem = () => {

            this.setState({ requesting: true, info: null });
            addSelfOrderItem(this.state.selectedDay, this.state.adding, this.newInputRef.current.value)
                .then(() => this.setState({ requesting: false, adding: null }))
                .catch((error) => {
                    if (error === "Name cannot be empty")
                        this.setState({ requesting: false, info: <Info>Veuillez saisir un nom !</Info> })
                    else this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> })
                });
        }

        const editItem = () => {

            const type = this.state.editing.split("").shift() === "p" ? "priorities" : "normal";
            const item = this.state.selfOrder
                .find((day) => day.day === this.state.selectedDay)[type]
                .find((item) => item.position === parseInt(this.state.editing.slice(1)));

            this.setState({ requesting: true, info: null });
            editSelfOrderItem(this.state.selectedDay, type, item.position, this.editInputRef.current.value, item.position, item.type)
                .then(() => this.setState({ requesting: false, editing: null }))
                .catch(() => this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> }));
        }

        const deleteItem = () => {

            const type = this.state.editing.split("").shift() === "p" ? "priorities" : "normal";
            const item = this.state.selfOrder
                .find((day) => day.day === this.state.selectedDay)[type]
                .find((item) => item.position === parseInt(this.state.editing.slice(1)));

            if (!window.confirm("Voulez vous vraiment supprimer " + item.name + " ?")) return;

            this.setState({ requesting: true, info: null });
            deleteSelfOrderItem(this.state.selectedDay, type, item.position)
                .then(() => this.setState({ requesting: false, editing: null }))
                .catch(() => this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> }));
        }

        return <div className="selfOrder">
            <div className="title">{document.title}</div>

            {this.state.requesting ? <Loading /> : null}
            {this.state.info}

            <div className="days">{this.state.selfOrder.map((day) => <Link to={"/selfOrder/" + day.day} key={day.day}
                className={this.state.selectedDay === day.day ? "selected" : ""}>{getTranslatedDay(day.day)}</Link>)}</div>

            <div className="order"><DragDropContext onDragEnd={onDragEnd}>

                <Droppable droppableId="priorities">{(provided) => <div {...provided.droppableProps} ref={provided.innerRef} className="list">

                    <div className="list-title">Priorités<i className="fas fa-plus-circle" onClick={() => this.setState({ adding: "priorities" })} /></div>

                    {this.state.selfOrder
                        .find((day) => day.day === this.state.selectedDay).priorities
                        .sort((a, b) => a.position - b.position)
                        .map((item, index) => <Draggable key={item.position} isDragDisabled={this.state.requesting} draggableId={"p" + index} index={index}>
                            {(provided, snapshot) => <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}
                                className={"item" + (snapshot.isDragging ? " dragging" : "")}>

                                {this.state.editing !== ("p" + index) ? <span>{item.name}</span>
                                    : <input autoFocus defaultValue={item.name} placeholder="Nom"
                                        ref={this.state.editing !== ("p" + index) ? null : this.editInputRef}
                                        onKeyDown={(event) => { if (event.key === "Enter") editItem() }} />}

                                <i className={this.state.editing !== ("p" + index) ? "fa-solid fa-pen" : "fa-solid fa-arrow-right editing"}
                                    onClick={this.state.editing !== ("p" + index)
                                        ? () => this.setState({ editing: "p" + index })
                                        : () => editItem(item)} />

                                {this.state.editing !== ("p" + index) ? null
                                    : <i className="fa-solid fa-trash-can editing" onClick={() => deleteItem(item)} />}
                            </div>}
                        </Draggable>)}

                    {this.state.adding === "priorities" ? <Draggable draggableId="new" isDragDisabled={true}
                        index={this.state.selfOrder.find((day) => day.day === this.state.selectedDay).priorities.length}
                    >{(provided) => <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className="new">
                        <input ref={this.newInputRef} autoFocus placeholder="Nom"
                            onKeyDown={(event) => { if (event.key === "Enter") addItem() }} />
                        <i className="fa-solid fa-xmark" onClick={() => this.setState({ adding: null })} />
                        <i className="fa-solid fa-arrow-right" onClick={() => addItem()} />
                    </div>}</Draggable> : null}

                    {provided.placeholder}
                </div>}</Droppable>

                <Droppable droppableId="normal">{(provided) => <div {...provided.droppableProps} ref={provided.innerRef} className="list">

                    <div className="list-title">Autres<i className="fas fa-plus-circle" onClick={() => this.setState({ adding: "normal" })} /></div>

                    {this.state.selfOrder
                        .find((day) => day.day === this.state.selectedDay).normal
                        .sort((a, b) => a.position - b.position)
                        .map((item, index) => <Draggable key={item.position} isDragDisabled={this.state.requesting} draggableId={"n" + index} index={index}>
                            {(provided, snapshot) => <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}
                                className={"item" + (snapshot.isDragging ? " dragging" : "")}>

                                {this.state.editing !== ("n" + index) ? <span>{item.name}</span>
                                    : <input autoFocus defaultValue={item.name} placeholder="Nom"
                                        ref={this.state.editing !== ("n" + index) ? null : this.editInputRef}
                                        onKeyDown={(event) => { if (event.key === "Enter") editItem() }} />}

                                <i className={this.state.editing !== ("n" + index) ? "fa-solid fa-pen" : "fa-solid fa-arrow-right editing"}
                                    onClick={this.state.editing !== ("n" + index)
                                        ? () => this.setState({ editing: "n" + index })
                                        : () => editItem(item)} />

                                {this.state.editing !== ("n" + index) ? null
                                    : <i className="fa-solid fa-trash-can editing" onClick={() => deleteItem(item)} />}
                            </div>}
                        </Draggable>)}

                    {this.state.adding === "normal" ? <Draggable draggableId="new" isDragDisabled={true}
                        index={this.state.selfOrder.find((day) => day.day === this.state.selectedDay).normal.length}
                    >{(provided) => <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className="new">
                        <input ref={this.newInputRef} autoFocus placeholder="Nom"
                            onKeyDown={(event) => { if (event.key === "Enter") addItem() }} />
                        <i className="fa-solid fa-xmark" onClick={() => this.setState({ adding: null })} />
                        <i className="fa-solid fa-arrow-right" onClick={() => addItem()} />
                    </div>}</Draggable> : null}

                    {provided.placeholder}
                </div>}</Droppable>
            </DragDropContext></div>
        </div>;
    }
}

// eslint-disable-next-line
export default (props) => <Update {...props} navigate={useNavigate()} params={useParams()} />;

const getTranslatedDay = (number) => ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"][number - 1];