import { Component } from "react";
import { Link, useLocation } from "react-router-dom";

class HeaderClass extends Component {
    render() {

        if (["/ip/print", "/selforder/display"].includes(this.props.location.pathname.toLowerCase()))
            return null;

        return <div className="header" id="header">
            <Link to="/home" className="home link-container">
                <img src="/logo.png" alt="Logo" />
                <div className="link">Collège Jeanne d'Arc Apt</div>
            </Link>
            <div className="links">
                <Link to="/ip" className="link">IP</Link>
                <Link to="/selfOrder/1" className="link">Ordre cantine</Link>
                <Link to="/selfOrder/control" className="link">Ecran cour</Link>
            </div>
            <div className="line" />
        </div>;
    }
}

export const Header = (props) => <HeaderClass {...props} location={useLocation()} />;

export class FooterClass extends Component {
    render() {

        if (["/ip/print", "/selforder/display"].includes(this.props.location.pathname.toLowerCase()))
            return null;

        return <div className="footer" id="footer">
            <div className="line" />
            <div>Fait par Raphaël avec ❤</div>
            <div>Un bug, une question, quelque chose à changer, contactez moi par email à <a href="mailto:contact@raraph.fr" className="link">contact@raraph.fr</a>.</div>
        </div>;
    }
}

export const Footer = (props) => <FooterClass {...props} location={useLocation()} />;

export class NotFound extends Component {
    render() {

        document.title = "Page introuvable";

        return <div>
            <div className="title">Cette page n'existe pas !</div>
            <div style={{ fontStyle: "italic", fontSize: "20px" }}>Ou pas encore</div>
        </div>;
    }
}

export class Info extends Component {
    render() {
        return <div className="info" style={{ color: this.props.color || "red" }}>{this.props.children}</div>
    }
}

export class Loading extends Component {
    render() {
        return <div className="loading"><i className="fas fa-spinner" /></div>
    }
}
