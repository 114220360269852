import moment from "moment";
import { Component } from "react";
import { GATEWAY_HOST } from "../api";
import { Loading } from "../other";

import "../styles/selfOrder.scss";

export default class Display extends Component {

    constructor(props) {

        super(props);

        this.ws = null;
        this.state = { requesting: true, displayedDay: 0, selfOrder: [], date: Date.now(), event: "" };
    }

    componentDidMount() {

        this.connect();

        setInterval(() => this.setState({ date: Date.now() }), 1000);
    }

    connect() {

        this.setState({ requesting: true });

        this.ws = new WebSocket(GATEWAY_HOST);

        this.ws.addEventListener("open", () => {
            this.ws.send(JSON.stringify({
                command: "LOGIN",
                token: localStorage.getItem("token")
            }));
        });

        this.ws.addEventListener("close", (event) => {
            if (event.reason === "Invalid token") {
                localStorage.removeItem("token");
                document.location.reload();
            } else
                setTimeout(() => this.connect(), 1000);
        });

        this.ws.addEventListener("message", (event) => {

            let message;
            try {
                message = JSON.parse(event.data);
            } catch (error) {
                return;
            }

            if (message.event === "LOGGED")
                this.setState({ requesting: false, selfOrder: message.selfOrderDays });
            else if (message.event === "SELF_ORDER_ADD") {
                const list = this.state.selfOrder.find((day) => day.day === message.day)[message.type.toLowerCase()];
                list.push({ name: message.name, position: message.position });
                this.setState({ selfOrder: this.state.selfOrder });
            } else if (message.event === "SELF_ORDER_EDIT") {
                const oldList = this.state.selfOrder.find((day) => day.day === message.day)[message.type.toLowerCase()];
                const newList = this.state.selfOrder.find((day) => day.day === message.day)[message.newType.toLowerCase()];
                const item = oldList.find((item) => item.position === message.position);
                oldList.splice(oldList.indexOf(item), 1);
                oldList.filter((val) => val.position > item.position).forEach((item) => item.position--);
                newList.filter((val) => val.position >= message.newPosition).forEach((item) => item.position++);
                newList.push({ name: message.newName, position: message.newPosition });
                this.setState({ selfOrder: this.state.selfOrder });
            } else if (message.event === "SELF_ORDER_DELETE") {
                const list = this.state.selfOrder.find((day) => day.day === message.day)[message.type.toLowerCase()];
                const item = list.find((item) => item.position === message.position);
                list.splice(list.indexOf(item), 1);
                list.filter((val) => val.position > item.position).forEach((item) => item.position--);
                this.setState({ selfOrder: this.state.selfOrder });
            } else if (message.event === "SELF_ORDER_DISPLAY_EDIT") {
                if (typeof message.displayedDay !== "undefined") {
                    fetch("https://raw.githubusercontent.com/theofidry/ephemeris/master/src/ephemeris.json")
                        .then((res) => res.json().then((res) => {
                            let dayEvent = Object.values(res)[new Date().getMonth()][new Date().getDate() - 1];
                            dayEvent = dayEvent[1] ? dayEvent[1] + " " + dayEvent[0] : dayEvent[0];
                            this.setState({ displayedDay: message.displayedDay, event: dayEvent });
                        }))
                        .catch(() => this.setState({ displayedDay: message.displayedDay, event: "" }));
                }
                if (typeof message.screenEnabled !== "undefined")
                    fetch("http://localhost:8000/tv/" + (message.screenEnabled ? "on" : "off"), { method: "POST" }).catch(() => { });
            } else if (message.event === "HEARTBEAT")
                this.ws.send(JSON.stringify({ command: "HEARTBEAT", }));
        });
    }

    render() {

        document.title = "Cantine" + (this.state.displayedDay > 0 ? " " + getTranslatedDay(this.state.displayedDay) : "") + " - Collège Jeanne d'Arc";

        return <div className="selfOrderDisplay">
            <div className="logo"><img src="/logo.png" alt="logo" /><span>{document.title}</span></div>

            {this.state.requesting ? <Loading /> : null}

            {this.state.displayedDay > 0 ? <div className="lists">
                <div className="list">
                    <div className="list-title">Priorités</div>
                    {this.state.selfOrder.find((day) => day.day === this.state.displayedDay).priorities
                        .sort((a, b) => a.position - b.position)
                        .map((item) => <div className="item" key={item.position}>{item.name}</div>)}
                </div>
                <div className="list">
                    <div className="list-title">Classes</div>
                    {this.state.selfOrder.find((day) => day.day === this.state.displayedDay).normal
                        .sort((a, b) => a.position - b.position)
                        .map((item) => <div className="item" key={item.position}>{item.name}</div>)}
                </div>
            </div> : null}

            {this.state.event ? <div className="event">Aujourd'hui : {this.state.event}</div> : null}

            <div className="date">{moment(this.state.date).format("DD/MM/YYYY HH:mm")}</div>
        </div>;
    }
}

const getTranslatedDay = (number) => ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"][number - 1];