import { Component, createRef } from "react";
import { login } from "./api";
import { Info, Loading } from "./other";

import "./styles/login.scss";

export default class Login extends Component {

    constructor(props) {

        super(props);

        this.passwordInput = createRef();

        this.state = { requesting: false, info: null };
    }

    render() {

        document.title = "Connexion - Collège Jeanne d'Arc";

        const processLogin = () => {

            this.setState({ requesting: true, info: null });
            login(this.passwordInput.current.value).then((token) => {
                localStorage.setItem("token", token);
                document.location.reload();
            }).catch((message) => {
                if (message === "Invalid password" || message === "Missing password")
                    this.setState({ requesting: false, info: <Info>Mot de passe invalide !</Info> });
                else if (message === "Too many login fails")
                    this.setState({ requesting: false, info: <Info>Trop d'échecs, réessayez plus tard !</Info> });
                else
                    this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
            });
        }

        return <div className="login">
            <div className="title">Veuillez vous connecter pour accéder à cette page</div>

            {this.state.requesting ? <Loading /> : null}
            {this.state.info}

            <div>
                <span className="hint">Mot de passe :</span>
                <input type="password" ref={this.passwordInput} disabled={this.state.requesting} autoFocus onKeyPress={(event) => { if (event.key === "Enter") processLogin(); }} />
            </div>

            <div><button disabled={this.state.requesting} onClick={() => processLogin()}>Se connecter</button></div>
        </div>;
    }
}
