import { Component } from "react";
import { editSelfOrderDisplay } from "../api";
import { Info, Loading } from "../other";

import "../styles/selfOrder.scss";

export default class Control extends Component {

    constructor(props) {

        super(props);

        this.state = { requesting: false, info: null };
    }

    render() {

        document.title = "Ecran cour - Collège Jeanne d'Arc";

        const edit = (edits) => {

            this.setState({ requesting: true, info: null });
            editSelfOrderDisplay(edits)
                .then(() => this.setState({ requesting: false }))
                .catch(() => this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> }));
        }

        return <div className="selfOrder">
            <div className="title">{document.title}</div>

            {this.state.requesting ? <Loading /> : null}
            {this.state.info}

            <div>
                <button disabled={this.state.requesting} onClick={() => edit({ screenEnabled: true })}>Allumer l'écran</button>
                <button disabled={this.state.requesting} onClick={() => edit({ screenEnabled: false })}>Eteindre l'écran</button>
            </div>

            <div>{[1, 2, 4, 5].map((day) => <button key={day} disabled={this.state.requesting}
                onClick={() => edit({ displayedDay: day })}>{getTranslatedDay(day)}</button>)}</div>
        </div>;
    }
}

const getTranslatedDay = (number) => ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"][number - 1];