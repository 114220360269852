import { Component, createRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { deleteIp, editIp, getIp, getIpCategories } from "../api";
import { Info, Loading } from "../other";

class EditIp extends Component {

    constructor(props) {

        super(props);

        this.ipInput = createRef();
        this.descriptionInput = createRef();
        this.categoryInput = createRef();

        this.state = { requesting: false, info: null, categories: [], ip: [] };
    }

    componentDidMount() {

        this.setState({ requesting: true });
        getIp().then((ip) => {
            getIpCategories().then((categories) => {
                this.setState({ requesting: false, ip, categories }, () => {
                    this.setIp(this.props.params.ip);
                });
            }).catch(() => {
                this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
            });
        }).catch(() => {
            this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
        });
    }

    setIp(ipName) {
        const ip = this.state.ip.find((ip) => ip.name === ipName);
        if (!ip) {
            this.setState({ info: <Info>Cette IP n'existe pas !</Info> });
            this.setIp(this.state.ip[0].name);
            return;
        }
        this.ipInput.current.value = ip.name;
        this.descriptionInput.current.value = ip.description;
        this.categoryInput.current.value = ip.categoryId;
    }

    render() {

        document.title = "Modifier une IP - Collège Jeanne d'Arc";

        const processEditIp = () => {

            this.setState({ requesting: true, info: null });
            editIp(this.ipInput.current.value, this.descriptionInput.current.value, this.categoryInput.current.value).then(() => {
                this.props.navigate("/ip");
            }).catch((message) => {
                if (message === "Invalid token") {
                    localStorage.removeItem("token");
                    document.location.reload();
                } else if (message === "Missing description")
                    this.setState({ requesting: false, info: <Info>Aucune description !</Info> });
                else
                    this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
            });
        }

        const processDeleteIp = () => {

            if (!window.confirm("Voulez vous vraiment supprimer l'IP " + this.ipInput.current.value + " ?"))
                return;

            this.setState({ requesting: true, info: null });
            deleteIp(this.ipInput.current.value).then(() => {
                this.props.navigate("/ip");
            }).catch((message) => {
                if (message === "Invalid token") {
                    localStorage.removeItem("token");
                    document.location.reload();
                } else
                    this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
            });
        }

        return <div className="ip">
            <div className="title">{document.title}</div>

            {this.state.requesting ? <Loading /> : null}
            {this.state.info}

            <div>
                <span className="hint">IP à modifier :</span>
                <select ref={this.ipInput} disabled={this.state.requesting} onChange={() => this.setIp(this.ipInput.current.value)}>
                    {this.state.ip.map((ip) => <option key={ip.name} value={ip.name}>{ip.name}</option>)}
                </select>
            </div>

            <div>
                <span className="hint">Description :</span>
                <input ref={this.descriptionInput} disabled={this.state.requesting} autoFocus />
            </div>

            <div>
                <span className="hint">Catégorie :</span>
                <select ref={this.categoryInput} disabled={this.state.requesting}>
                    {this.state.categories.map((category) => <option key={category.id} value={category.id}>{category.name}</option>)}
                </select>
            </div>

            <div>
                <button disabled={this.state.requesting} onClick={processEditIp}>Modifier</button>
                <button disabled={this.state.requesting} onClick={processDeleteIp}>Supprimer</button>
            </div>
        </div>;
    }
}

// eslint-disable-next-line
export default (props) => <EditIp {...props} navigate={useNavigate()} params={useParams()} />;