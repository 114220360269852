import { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getIp, getIpCategories } from "../api";
import { Info, Loading } from "../other";

import "../styles/ip.scss";

class Home extends Component {

    constructor(props) {

        super(props);

        this.state = { requesting: false, info: null, categories: [], ip: [] };
    }

    componentDidMount() {

        this.setState({ requesting: true });
        getIp().then((ip) => {
            getIpCategories().then((categories) => {
                this.setState({ requesting: false, ip, categories });
            }).catch((message) => {
                if (message === "Invalid token") {
                    localStorage.removeItem("token");
                    document.location.reload();
                } else
                    this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
            });
        }).catch((message) => {
            if (message === "Invalid token") {
                localStorage.removeItem("token");
                document.location.reload();
            } else
                this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
        });
    }

    render() {

        document.title = "IP - Collège Jeanne d'Arc";

        const unusedIps = [];
        for (let i = 1; i <= 255; i++)
            if (!this.state.ip.some((ip) => ip.name === `192.168.0.${i}`))
                unusedIps.push(`192.168.0.${i}`);

        return <div className="ip">
            <div className="title">{document.title}</div>
            <Link to="/ip/print" className="link print"><i className="fa-solid fa-print" /> Imprimer</Link>

            {this.state.requesting ? <Loading /> : null}
            {this.state.info}

            {this.state.requesting ? null : <div>
                <div className="type">IP Utilisées<i className="fas fa-plus-circle" onClick={() => this.props.navigate("/ip/categories/new")} /></div>
                <div>{this.state.categories.map((category) => <div key={category.id}>
                    <div className="category">{category.name}<i className="fas fa-pen" onClick={() => this.props.navigate("/ip/categories/" + category.id)} /></div>
                    <div>{this.state.ip.filter((ip) => ip.categoryId === category.id).map((ip) =>
                        <div key={ip.name} className="ip">{ip.name} - {ip.description}<i className="fas fa-pen" onClick={() => this.props.navigate("/ip/" + ip.name)} /></div>
                    )}</div>
                </div>)}</div>

                <div className="type">IP Disponibles</div>
                <div>{unusedIps.map((ip) =>
                    <div key={ip} className="ip">{ip}<i className="fas fa-plus-circle" onClick={() => this.props.navigate("/ip/new?ip=" + ip)} /></div>
                )}</div>
            </div>}
        </div>;
    }
}

// eslint-disable-next-line
export default (props) => <Home {...props} navigate={useNavigate()} />;