export const HOST = process.env.NODE_ENV === "production" ? "https://api.jdapt.raraph.fr" : "http://" + document.location.hostname + ":8004";
export const GATEWAY_HOST = process.env.NODE_ENV === "production" ? "wss://gateway.jdapt.raraph.fr" : "ws://" + document.location.hostname + ":8005";

export const login = (password) => new Promise((resolve, reject) => {

    fetch(HOST + "/login", {
        method: "POST",
        body: JSON.stringify({ password })
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.token)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getIp = () => new Promise((resolve, reject) => {

    fetch(HOST + "/ip", {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.ip)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const newIp = (ip, description, categoryId) => new Promise((resolve, reject) => {

    fetch(HOST + "/ip", {
        method: "POST",
        headers: { authorization: localStorage.getItem("token") },
        body: JSON.stringify({ ip, description, categoryId })
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const editIp = (ip, description, categoryId) => new Promise((resolve, reject) => {

    fetch(HOST + "/ip/" + ip, {
        method: "PATCH",
        headers: { authorization: localStorage.getItem("token") },
        body: JSON.stringify({ description, categoryId })
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const deleteIp = (ip) => new Promise((resolve, reject) => {

    fetch(HOST + "/ip/" + ip, {
        method: "DELETE",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getIpCategories = () => new Promise((resolve, reject) => {

    fetch(HOST + "/ip/categories", {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.categories)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const newCategory = (name) => new Promise((resolve, reject) => {

    fetch(HOST + "/ip/categories", {
        method: "POST",
        headers: { authorization: localStorage.getItem("token") },
        body: JSON.stringify({ name })
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const editCategory = (categoryId, name) => new Promise((resolve, reject) => {

    fetch(HOST + "/ip/categories/" + categoryId, {
        method: "PATCH",
        headers: { authorization: localStorage.getItem("token") },
        body: JSON.stringify({ name })
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const deleteCategory = (categoryId) => new Promise((resolve, reject) => {

    fetch(HOST + "/ip/categories/" + categoryId, {
        method: "DELETE",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const addSelfOrderItem = (day, type, name) => new Promise((resolve, reject) => {

    fetch(HOST + "/selfOrder/" + day + "/" + type, {
        method: "POST",
        headers: { authorization: localStorage.getItem("token") },
        body: JSON.stringify({ name: name })
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const editSelfOrderItem = (day, type, position, newName, newPosition, newType) => new Promise((resolve, reject) => {

    fetch(HOST + "/selfOrder/" + day + "/" + type + "/" + position, {
        method: "PATCH",
        headers: { authorization: localStorage.getItem("token") },
        body: JSON.stringify({ name: newName, position: newPosition, type: newType })
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const deleteSelfOrderItem = (day, type, position) => new Promise((resolve, reject) => {

    fetch(HOST + "/selfOrder/" + day + "/" + type + "/" + position, {
        method: "DELETE",
        headers: { authorization: localStorage.getItem("token") },
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const editSelfOrderDisplay = (edits) => new Promise((resolve, reject) => {

    fetch(HOST + "/selfOrder/display", {
        method: "PATCH",
        headers: { authorization: localStorage.getItem("token") },
        body: JSON.stringify(edits)
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});
