import { Component, createRef } from "react";
import { useNavigate } from "react-router-dom";
import { getIp, getIpCategories, newIp } from "../api";
import { Info, Loading } from "../other";

class NewIp extends Component {

    constructor(props) {

        super(props);

        this.ipInput = createRef();
        this.descriptionInput = createRef();
        this.categoryInput = createRef();

        this.state = { requesting: false, info: null, categories: [], ip: [] };
    }

    componentDidMount() {

        const urlParams = new URLSearchParams(document.location.search);
        if (urlParams.has("ip"))
            this.ipInput.current.value = urlParams.get("ip");

        this.setState({ requesting: true });
        getIp().then((ip) => {
            getIpCategories().then((categories) => {
                this.setState({ requesting: false, ip, categories });
            }).catch(() => {
                this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
            });
        }).catch(() => {
            this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
        });
    }

    render() {

        document.title = "Nouvelle IP - Collège Jeanne d'Arc";

        const unusedIps = [];
        for (let i = 1; i <= 255; i++)
            if (!this.state.ip.some((ip) => ip.name === `192.168.0.${i}`))
                unusedIps.push(`192.168.0.${i}`);

        const processNewIp = () => {

            this.setState({ requesting: true, info: null });
            newIp(this.ipInput.current.value, this.descriptionInput.current.value, this.categoryInput.current.value).then(() => {
                this.props.navigate("/ip");
            }).catch((message) => {
                if (message === "Invalid token") {
                    localStorage.removeItem("token");
                    document.location.reload();
                } else if (message === "Missing description")
                    this.setState({ requesting: false, info: <Info>Aucune description !</Info> });
                else
                    this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
            });
        }

        return <div className="ip">
            <div className="title">{document.title}</div>

            {this.state.requesting ? <Loading /> : ""}
            {this.state.info}

            <div>
                <span className="hint">IP :</span>
                <select ref={this.ipInput} disabled={this.state.requesting} defaultValue={this.defaultSelectedIp} onChange={() => this.descriptionInput.current.focus()}>
                    {unusedIps.map((ip) => <option key={ip} value={ip}>{ip}</option>)}
                </select>
            </div>

            <div>
                <span className="hint">Description :</span>
                <input ref={this.descriptionInput} disabled={this.state.requesting} autoFocus />
            </div>

            <div>
                <span className="hint">Catégorie :</span>
                <select ref={this.categoryInput} disabled={this.state.requesting}>
                    {this.state.categories.map((category) => <option key={category.id} value={category.id}>{category.name}</option>)}
                </select>
            </div>

            <div><button disabled={this.state.requesting} onClick={processNewIp}>Ajouter</button></div>
        </div>;
    }
}

// eslint-disable-next-line
export default (props) => <NewIp {...props} navigate={useNavigate()} />;